import React from 'react'
import styled from 'styled-components'

const LogoContainer = styled.div`
  height: 40px;
  margin: 16px;
`

const LogoContainer2 = styled.div`
  height: 28px;
  margin: 16px 8px;
`

const Logo = ({ collapsed }) => {
  if (collapsed)
    return (
      <LogoContainer2>
        <img src="/Voila-LMS-mini.png" alt="logo" style={{ width: '4.2rem' }} />
      </LogoContainer2>
    )
  return (
    <LogoContainer>
      <img src="/Voila-LMS.png" alt="logo" style={{ width: '11rem' }} />
    </LogoContainer>
  )
}

export default Logo
