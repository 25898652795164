import { Layout } from 'antd'
import Styled from 'styled-components'

const { Footer } = Layout

const S = {}

S.Footer = Styled(Footer)`
    text-align: center;
    box-shadow: 0px -3px 30px #ddd;
`

export default S
